import React from 'react';
import useBuildingSchedules from '../hooks/useBuildingSchedules';
import './BuildingSchedules.css';

const BuildingSchedules = () => {
  console.log('Rendering Exemptions component');
  const {
    buildingSchedules,
    newBuildingSchedule,
    setNewBuildingSchedule,
    handleAddBuildingSchedule,
    handleUpdateBuildingSchedule,
    handleDeleteBuildingSchedule,
    handleBuildingScheduleInputChange,
    updateSuccess // Destructure the success message
  } = useBuildingSchedules();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>} {/* Display success message */}
      <div className="info-section">
        <p>
          Under 400 SF: $69.00 per SF<br />
          Over 2400 SF: $45.00 per SF
        </p>
      </div>
      <div className="building-schedules">
        <table className="building-schedules-table">
          <thead>
            <tr>
              <th>Area (sf)</th>
              <th>Base Value</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {buildingSchedules.map((buildingSchedule, index) => (
              <tr key={buildingSchedule.id}>
                <td>
                  <input
                    type="number"
                    value={buildingSchedule.area_sf}
                    onChange={(e) => handleBuildingScheduleInputChange(index, 'area_sf', e.target.value)}
                    placeholder="Area (sf)"
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={buildingSchedule.base}
                    onChange={(e) => handleBuildingScheduleInputChange(index, 'base', e.target.value)}
                    placeholder="Base Value"
                  />
                </td>
                <td className="button-group">
                  <button
                    onClick={() => handleUpdateBuildingSchedule(buildingSchedule.id, buildingSchedule)}
                    className="update-button"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteBuildingSchedule(buildingSchedule.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            <tr className="new-building-schedule">
              <td>
                <input
                  type="number"
                  value={newBuildingSchedule.area_sf}
                  onChange={(e) => setNewBuildingSchedule({ ...newBuildingSchedule, area_sf: e.target.value })}
                  placeholder="New Area (sf)"
                />
              </td>
              <td>
                <input
                  type="number"
                  value={newBuildingSchedule.base}
                  onChange={(e) => setNewBuildingSchedule({ ...newBuildingSchedule, base: e.target.value })}
                  placeholder="New Base Value"
                />
              </td>
              <td>
                <button
                  onClick={handleAddBuildingSchedule}
                  className="add-button"
                >
                  Add Building Schedule
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default BuildingSchedules;
