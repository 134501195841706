import React from 'react';
import useLandUse from '../hooks/useLandUse';
import './LandUse.css';

const LandUse = () => {
  console.log('Rendering Exemptions component');
  const {
    landUses,
    newLandUse,
    setNewLandUse,
    handleAddLandUse,
    handleUpdateLandUse,
    handleDeleteLandUse,
    handleLandUseInputChange,
    updateSuccess
  } = useLandUse();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="land-uses">
        <table className="land-uses-table">
          <thead>
            <tr>
              <th>Land Use Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {landUses.map((landUse, index) => (
              <tr key={landUse.id}>
                <td>
                  <input
                    type="text"
                    value={landUse.land_use_type}
                    onChange={(e) => handleLandUseInputChange(index, 'land_use_type', e.target.value)}
                    placeholder="Land Use Type"
                  />
                </td>
                <td className="button-group">
                  <button
                    onClick={() => handleUpdateLandUse(landUse.id, landUse)}
                    className="update-button"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteLandUse(landUse.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            <tr className="new-land-use">
              <td>
                <input
                  type="text"
                  value={newLandUse.land_use_type}
                  onChange={(e) => setNewLandUse({ ...newLandUse, land_use_type: e.target.value })}
                  placeholder="New Land Use Type"
                />
              </td>
              <td>
                <button
                  onClick={handleAddLandUse}
                  className="add-button"
                >
                  Add Land Use
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default LandUse;