import { useState, useEffect } from 'react';
import axiosInstance from '../../../axiosInstance';

const usePropertyOverview = (accountNumber) => {
  const [overviewData, setOverviewData] = useState({
    currentOwners: [],
    previousOwners: [],
    assessmentHistory: []
  });

  useEffect(() => {
    if (accountNumber) {
      fetchOverviewData();
    }
  }, [accountNumber]);

  const fetchOverviewData = async () => {
    try {
      const response = await axiosInstance.get(`/api/property/overview/${accountNumber}`);
      setOverviewData(response.data);
    } catch (error) {
      console.error('Error fetching property overview data:', error);
    }
  };

  const updateOverviewData = async (updatedData) => {
    try {
      await axiosInstance.put(`/api/property/overview/${accountNumber}`, updatedData);
      setOverviewData(updatedData);
    } catch (error) {
      console.error('Error updating property overview data:', error);
    }
  };

  return {
    overviewData,
    updateOverviewData,
  };
};

export default usePropertyOverview;