import React from 'react';
import useZones from '../hooks/useZones';
import './Zones.css';

const Zones = () => {
  console.log('Rendering Exemptions component');
  const {
    zones,
    newZone,
    setNewZone,
    handleAddZone,
    handleUpdateZone,
    handleDeleteZone,
    handleZoneInputChange,
    updateSuccess
  } = useZones();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="zones">
        <table className="zones-table">
          <thead>
            <tr>
              <th>Zone Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {zones.map((zone, index) => (
              <tr key={zone.id}>
                <td>
                  <input
                    type="text"
                    value={zone.zone_type}
                    onChange={(e) => handleZoneInputChange(index, 'zone_type', e.target.value)}
                    placeholder="Zone Type"
                  />
                </td>
                <td className="button-group">
                  <button
                    onClick={() => handleUpdateZone(zone.id, zone)}
                    className="update-button"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteZone(zone.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            <tr className="new-zone">
              <td>
                <input
                  type="text"
                  value={newZone.zone_type}
                  onChange={(e) => setNewZone({ ...newZone, zone_type: e.target.value })}
                  placeholder="New Zone Type"
                />
              </td>
              <td>
                <button
                  onClick={handleAddZone}
                  className="add-button"
                >
                  Add Zone
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Zones;