import React from 'react';
import useFoundationTypes from '../hooks/useFoundationTypes';
import './FoundationTypes.css';

const FoundationTypes = () => {
  console.log('Rendering Exemptions component');
  const {
    foundationTypes,
    newFoundationType,
    setNewFoundationType,
    handleAddFoundationType,
    handleUpdateFoundationType,
    handleDeleteFoundationType,
    handleFoundationTypeInputChange,
    updateSuccess
  } = useFoundationTypes();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="foundation-types">
        <table className="foundation-types-table">
          <thead>
            <tr>
              <th>Foundation Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {foundationTypes.map((foundationType, index) => (
              <tr key={foundationType.id}>
                <td>
                  <input
                    type="text"
                    value={foundationType.type_name}
                    onChange={(e) => handleFoundationTypeInputChange(index, 'type_name', e.target.value)}
                    placeholder="Foundation Type"
                  />
                </td>
                <td className="button-group">
                  <button
                    onClick={() => handleUpdateFoundationType(foundationType.id, foundationType)}
                    className="update-button"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteFoundationType(foundationType.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            <tr className="new-foundation-type">
              <td>
                <input
                  type="text"
                  value={newFoundationType.type_name}
                  onChange={(e) => setNewFoundationType({ ...newFoundationType, type_name: e.target.value })}
                  placeholder="New Foundation Type"
                />
              </td>
              <td>
                <button
                  onClick={handleAddFoundationType}
                  className="add-button"
                >
                  Add Foundation Type
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default FoundationTypes;