import React from 'react';
import useStreetType from '../hooks/useStreetType';
import './StreetType.css';

const StreetType = () => {
  console.log('Rendering Exemptions component');
  const {
    streetTypes,
    newStreetType,
    setNewStreetType,
    handleAddStreetType,
    handleUpdateStreetType,
    handleDeleteStreetType,
    handleStreetTypeInputChange,
    updateSuccess
  } = useStreetType();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="street-types">
        <table className="street-types-table">
          <thead>
            <tr>
              <th>Street Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {streetTypes.map((streetType, index) => (
              <tr key={streetType.id}>
                <td>
                  <input
                    type="text"
                    value={streetType.street_type}
                    onChange={(e) => handleStreetTypeInputChange(index, 'street_type', e.target.value)}
                    placeholder="Street Type"
                  />
                </td>
                <td className="button-group">
                  <button
                    onClick={() => handleUpdateStreetType(streetType.id, streetType)}
                    className="update-button"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteStreetType(streetType.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            <tr className="new-street-type">
              <td>
                <input
                  type="text"
                  value={newStreetType.street_type}
                  onChange={(e) => setNewStreetType({ ...newStreetType, street_type: e.target.value })}
                  placeholder="New Street Type"
                />
              </td>
              <td>
                <button
                  onClick={handleAddStreetType}
                  className="add-button"
                >
                  Add Street Type
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default StreetType;