import React from 'react';
import useRoofingTypes from '../hooks/useRoofingTypes';
import './RoofingTypes.css';

const RoofingTypes = () => {
  console.log('Rendering Exemptions component');
  const {
    roofingTypes,
    newRoofingType,
    setNewRoofingType,
    handleAddRoofingType,
    handleUpdateRoofingType,
    handleDeleteRoofingType,
    handleRoofingTypeInputChange,
    updateSuccess
  } = useRoofingTypes();

  return (
    <>
      {updateSuccess && <div className="update-success">{updateSuccess}</div>}
      <div className="roofing-types">
        <table className="roofing-types-table">
          <thead>
            <tr>
              <th>Roofing Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {roofingTypes.map((roofingType, index) => (
              <tr key={roofingType.id}>
                <td>
                  <input
                    type="text"
                    value={roofingType.type_name}
                    onChange={(e) => handleRoofingTypeInputChange(index, 'type_name', e.target.value)}
                    placeholder="Roofing Type"
                  />
                </td>
                <td className="button-group">
                  <button
                    onClick={() => handleUpdateRoofingType(roofingType.id, roofingType)}
                    className="update-button"
                  >
                    Update
                  </button>
                  <button
                    onClick={() => handleDeleteRoofingType(roofingType.id)}
                    className="delete-button"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
            <tr className="new-roofing-type">
              <td>
                <input
                  type="text"
                  value={newRoofingType.type_name}
                  onChange={(e) => setNewRoofingType({ ...newRoofingType, type_name: e.target.value })}
                  placeholder="New Roofing Type"
                />
              </td>
              <td>
                <button
                  onClick={handleAddRoofingType}
                  className="add-button"
                >
                  Add Roofing Type
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default RoofingTypes;