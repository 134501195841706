import React, { useState } from 'react';
import { Rnd } from 'react-rnd';
import './PropertyOverview.css';
import usePropertyOverview from '../hooks/usePropertyOverview';

const PropertyOverview = ({ accountNumber }) => {
  const { overviewData, updateOverviewData } = usePropertyOverview(accountNumber);
  const { currentOwners, previousOwners, assessmentHistory, exemptions } = overviewData;

  const [boxes, setBoxes] = useState({
    currentOwners: { x: 8, y: 8, width: 804, height: 274 },
    exemptions: { x: 8, y: 292, width: 804, height: 300 }, // Added exemptions box
    previousOwners: { x: 8, y: 602, width: 805, height: 300 },
    assessmentRecord: { x: 821, y: 8, width: 640, height: 584 },
  });

  const updateBox = (id, newPos) => {
    setBoxes(prev => ({
      ...prev,
      [id]: { ...prev[id], ...newPos }
    }));
  };

  const renderBox = (id, content) => (
    <Rnd
      size={{ width: boxes[id].width, height: boxes[id].height }}
      position={{ x: boxes[id].x, y: boxes[id].y }}
      onDragStop={(e, d) => updateBox(id, { x: d.x, y: d.y })}
      onResizeStop={(e, direction, ref, delta, position) => {
        updateBox(id, {
          width: ref.offsetWidth,
          height: ref.offsetHeight,
          ...position,
        });
      }}
      bounds="parent"
    >
      {content}
    </Rnd>
  );

  return (
    <div className="property-overview">
      {renderBox('currentOwners', 
        <div className="current-owners">
          <h3>Current Owner(s)</h3>
          <div className="owners-list">
            {currentOwners.map((owner, index) => (
              <div key={index} className="owner-info">
                <p><strong>Name:</strong> {owner.name}</p>
                <p><strong>Address:</strong> {owner.address}</p>
              </div>
            ))}
          </div>
          {currentOwners.length > 0 && (
            <div className="deed-info">
              <p>
                <strong>Deed Book:</strong> {currentOwners[0].deedBook}
                <strong className="deed-separator">Deed Page:</strong> {currentOwners[0].deedPage}
                <strong className="deed-separator">Deed Date:</strong> {currentOwners[0].deedDate}
              </p>
            </div>
          )}
        </div>
      )}
      
      {renderBox('exemptions',
        <div className="exemptions">
          <h3>Exemptions</h3>
          {exemptions && exemptions.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {exemptions.map((exemption, index) => (
                  <tr key={index}>
                    <td>{exemption.type}</td>
                    <td>${exemption.amount.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No exemptions available.</p>
          )}
        </div>
      )}

      {renderBox('previousOwners',
        <div className="previous-owners">
          <h3>Previous Owners</h3>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Deed Book</th>
                <th>Deed Page</th>
                <th>Deed Date</th>
              </tr>
            </thead>
            <tbody>
              {previousOwners.map((owner, index) => (
                <tr key={index}>
                  <td>{owner.name}</td>
                  <td>{owner.deedBook}</td>
                  <td>{owner.deedPage}</td>
                  <td>{owner.deedDate}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      
      {renderBox('assessmentRecord',
        <div className="assessment-record">
          <h3>Assessment Record</h3>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Year</th>
                  <th>Land Value</th>
                  <th>Building Value</th>
                  <th>Exemptions</th>
                  <th>Total Value</th>
                </tr>
              </thead>
              <tbody>
                {assessmentHistory.map((record, index) => (
                  <tr key={index}>
                    <td>{record.year}</td>
                    <td>${record.landValue.toLocaleString()}</td>
                    <td>${record.buildingValue.toLocaleString()}</td>
                    <td>${record.exemptions.toLocaleString()}</td>
                    <td>${record.totalValue.toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <div className="position-display">
        <h4>Current Positions and Sizes:</h4>
        {Object.entries(boxes).map(([key, value]) => (
          <p key={key}>{key}: x: {value.x}, y: {value.y}, width: {value.width}px, height: {value.height}px</p>
        ))}
      </div>
    </div>
  );
};

export default PropertyOverview;
